var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "a",
      {
        staticClass: "lnk-classrooms-title",
      },
      [
        _c(
          "h2",
          {
            staticClass: "m-0 h2-classrooms-title",
          },
          [
            _c(
              "a",
              {
                class: _vm.classroomData.class_room.material.download_flag
                  ? ""
                  : "disabled-link",
                attrs: {
                  href: _vm.classroomData.class_room.material.file,
                },
              },
              [
                _vm.classroom_type === "academyTalks"
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.classroomData.class_room.title)),
                    ])
                  : _c("span", [
                      _vm._v(
                        _vm._s(_vm.classroomData.class_room.material.title)
                      ),
                    ]),
              ]
            ),
          ]
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "are-classrooms-info",
      },
      [
        _c(
          "div",
          {
            staticClass: "row",
          },
          [
            _c(
              "div",
              {
                staticClass: "blk-classrooms-info col-12 col-md-6",
              },
              [
                _c("font-awesome-icon", {
                  attrs: {
                    icon: "calendar-minus",
                  },
                }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getDateWithTimeMinute(_vm.classroomData.class_time)
                    )
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "blk-classrooms-info col-12 col-md-6",
              },
              [
                !_vm.isDemo(_vm.classroomData.class_type_id) &&
                _vm.getCanOrderClass
                  ? _c(
                      "div",
                      {
                        staticClass: "a-color",
                      },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "ConsultantDetail",
                                params: {
                                  id: _vm.classroomData.class_room.consultants
                                    .id,
                                },
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: {
                                icon: "user",
                              },
                            }),
                            _vm._v(
                              _vm._s(
                                _vm.classroomData.class_room.consultants
                                  .english_name
                              )
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c("div", [
                      _c(
                        "span",
                        {
                          staticClass: "a-color",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: {
                              icon: "user",
                            },
                          }),
                          _vm._v(
                            _vm._s(
                              _vm.classroomData.class_room.consultants
                                .english_name
                            )
                          ),
                        ],
                        1
                      ),
                    ]),
              ]
            ),
            _vm.classroom_type !== "evaluations"
              ? _c(
                  "div",
                  {
                    staticClass: "blk-classrooms-info col-12 col-md-6",
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: {
                        icon: "book-open",
                      },
                    }),
                    _vm._l(
                      _vm.classroomData.class_room.material.interested_topics,
                      function (topic, t_index) {
                        return _c("span", [
                          t_index != 0 ? _c("span", [_vm._v(" ,")]) : _vm._e(),
                          _c("span", [_vm._v(_vm._s(topic.title))]),
                        ])
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm.classroom_type !== "evaluations"
              ? _c(
                  "div",
                  {
                    staticClass: "blk-classrooms-info col-12 col-md-6",
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: {
                        icon: "users",
                      },
                    }),
                    _vm._v(_vm._s(_vm.classroomData.class_type)),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }