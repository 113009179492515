var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "blk-classrooms-control blk-classrooms-control-col4",
    },
    [
      _c(
        "a",
        {
          class: _vm.notAllowDownload ? "lnk-disabled" : "",
          attrs: {
            href: "javascript:;",
          },
          on: {
            click: function ($event) {
              return _vm.onDownloadFiles()
            },
          },
        },
        [
          _c("i", {
            staticClass: "fas fa-file-download",
          }),
          _vm._v(_vm._s(_vm.$t("download_files")) + " "),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }