var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "a",
      {
        staticClass: "disable-click",
        attrs: {
          href: "javascript:;",
        },
      },
      [
        _c(
          "b-alert",
          {
            attrs: {
              show: "",
              variant: "danger",
            },
          },
          [_vm._v(_vm._s(_vm.$t("classFullMessage")))]
        ),
      ],
      1
    ),
    false
      ? _c(
          "a",
          {
            attrs: {
              href: "javascript:;",
            },
          },
          [
            _c("i", {
              staticClass: "fa fa-plus",
            }),
            _vm._v(_vm._s(_vm.$t("class.enter_class"))),
          ]
        )
      : _vm._e(),
    false
      ? _c(
          "a",
          {
            attrs: {
              href: "javascript:;",
            },
          },
          [
            _c("i", {
              staticClass: "fa fa-plus",
            }),
            _vm._v(_vm._s(_vm.$t("class.enter_class"))),
          ]
        )
      : _vm._e(),
    false
      ? _c(
          "a",
          {
            staticClass: "disable-click",
            attrs: {
              href: "javascript:;",
            },
          },
          [
            _c("i", {
              staticClass: "fa fa-times",
            }),
            _vm._v(_vm._s(_vm.$t("class.enter_class"))),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }