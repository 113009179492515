var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "a",
              {
                attrs: {
                  href: "javascript:;",
                },
                on: {
                  click: function ($event) {
                    return _vm.onRedirectEnterClass(
                      _vm.classroomData,
                      _vm.currentTime,
                      _vm.vipType
                    )
                  },
                },
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "mr-2",
                  attrs: {
                    icon: "sign-in-alt",
                  },
                }),
                _vm._v(_vm._s(_vm.$t("class.enter_class"))),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "font",
        {
          staticClass: "text-danger",
          staticStyle: {
            "font-size": "0.875rem",
          },
        },
        [_vm._v(_vm._s(_vm.$t("classroom.ios_browser_remind")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }