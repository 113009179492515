var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "a",
    {
      attrs: {
        href: "javascript:;",
      },
    },
    [
      _c("font-awesome-icon", {
        attrs: {
          icon: "plus",
        },
      }),
      _vm._v(" " + _vm._s(_vm.$t("class.enter_class")) + " "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }