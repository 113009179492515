var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "a",
        {
          class: _vm.notAllowDownload ? "lnk-disabled" : "",
          attrs: {
            id: "lnk-videoList-" + _vm.classroomId,
            href: "javascript:;",
          },
        },
        [
          _c("font-awesome-icon", {
            attrs: {
              icon: "caret-square-right",
            },
          }),
          _vm._v(_vm._s(_vm.$t("class_records.video_download"))),
        ],
        1
      ),
      _c(
        "b-popover",
        {
          attrs: {
            target: "lnk-videoList-" + _vm.classroomId,
            placement: "top",
            title: _vm.$t("class_records.video_download"),
          },
        },
        [
          _vm.classroomVideo.length > 0
            ? _c(
                "b-list-group",
                _vm._l(_vm.classroomVideo, function (video, v_index) {
                  return _c(
                    "b-list-group-item",
                    {
                      key: video.id,
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "javascript:;",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.downloadVideos(_vm.classroomId)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: {
                              icon: "download",
                            },
                          }),
                          _vm._v(_vm._s("Video " + (v_index + 1))),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              )
            : [_vm._v(_vm._s(_vm.$t("class_records.not_yet_uploaded")))],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }